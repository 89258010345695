import { BaseModel } from './basemodel';

export class Plan implements BaseModel {
  id?: string;
  name: string;
  price: number;
  planId: string;
  maxDays: number;
  maxUsers: number;
  eventQty: number;
  isActive: boolean;
  isPublic: boolean;
  addRegCost: number;
  maxInvites: number;
  stripeSkuId: string;
  maxRegistrants: number;
  bestOption?: boolean;
}
