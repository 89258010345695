import { AppState } from '@store';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { v4 as uuid } from 'uuid';

import { Company } from '@core/models/company';
import { ModifyDataAction } from '@store/firestore/firestore.actions';
import { CompanyReducer } from '@store/companies/company.firestore.reducer';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  private basePath = '/imagesProfile/';

  constructor(private store: Store<AppState>, private storage: AngularFireStorage) {}

  updateCompanyPicture(company: Company, file: File): void {
    const companyId = company.id;
    this.uploadFile(companyId, file)
      .pipe(
        map(fileUrl => {
          const preferences = { ...company.preferences };
          preferences.imageProfile = fileUrl;

          const modifiedCompany = { ...company, preferences };

          const actionType = CompanyReducer.getInstance().MODIFY_ACTION;
          const action = new ModifyDataAction(actionType, modifiedCompany);
          this.store.dispatch(action);
        })
      )
      .subscribe();
  }

  private uploadFile(companyId: string, file: File): Observable<string> {
    const fullPath = `${this.basePath}${companyId}`;
    return this.uploadImage(fullPath, file);
  }

  uploadImage(path: string, file: File): Observable<string> {
    const fullPath = `${path}/${file.name}_${uuid()}`;
    const uploadTask = this.storage.upload(fullPath, file);

    const uploadPromise = uploadTask.then(snapshot => {
      if (snapshot.state !== 'success') {
        return '';
      }

      return snapshot.ref.getDownloadURL() as Promise<string>;
    });

    return from(uploadPromise);
  }
}
